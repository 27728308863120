import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

function CookieLoader() {
  const { i18n } = useTranslation();

  const [lang, setLang] = useState(null);

  // we're doing all this to make sure we only use lang when the language changes
  // if the acceptcookies cookie is not true or false, then reload the page
  useEffect(() => {
    if (i18n.language == null) return;

    const l = i18n.language?.substring(0, 2);

    // set lang only once
    if (lang == null) {
      setLang(l);
      return; // no need to reload the first time we're setting lang
    }

    if (lang === l) return;

    // if the acceptcookies cookie is not true or false, then reload the page
    let acceptCookies = Cookies.get("acceptcookies");
    if (acceptCookies !== "true" && acceptCookies !== "false") {
      window.location.href = window.location.href;
    }
  }, [i18n.language]);

  useEffect(() => {
    if (lang == null) {
      return;
    }

    const scriptId = "cookie-control-script";

    // Check if the script already exists
    let existingScript = document.getElementById(scriptId);
    if (existingScript) {
      existingScript.remove(); // Remove the existing script
    }

    let existingCookieBanner = document.getElementsByClassName("cookie-banner");
    if (existingCookieBanner) {
      for (let i = 0; i < existingCookieBanner.length; i++) {
        existingCookieBanner.item(i).remove();
      }
    }

    let cookieControlScript = document.createElement("script");
    cookieControlScript.id = scriptId; // Set the ID for the new script
    cookieControlScript.src = "/cookie-control.js";
    cookieControlScript.type = "module";
    cookieControlScript.defer = true;
    cookieControlScript.crossOrigin = "use-credentials";
    cookieControlScript.onload = () => {
      // firing load event to trigger the cookie control script
      setTimeout(() => {
        let loadEvent = new Event("load");
        window.dispatchEvent(loadEvent);
      }, 1);
    };

    // delaying adding the script, to allow the lang-code div to be added first
    setTimeout(() => {
      document.body.appendChild(cookieControlScript);
    }, 1);

    return () => {
      // Remove the script when the component unmounts
      let existingScript = document.getElementById(scriptId);
      if (existingScript) {
        existingScript.remove(); // Remove the existing script
      }
    };
  }, [lang]);

  return <div id="lang-code" data-code={lang}></div>;
}

export default CookieLoader;
